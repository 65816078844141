@import "../../styles/variables.scss";

.table {
    width: 914px;
    border-spacing: 0;
    border-collapse: collapse;
    border-top: 4px solid black;
    table-layout: fixed;

    &:not(.grouped) td:first-child {
        width: 40%;
    }
}

.headerRow {
    td {
        border-bottom: 2px solid black;
        border-right: 0.5px solid black;

        &:last-child {
            border-right: none;
        }
    }
}

.headerCell {
    @include font-bold;
    font-size: 16px;
    white-space: pre-line;

    padding: 12px;
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img,
    svg {
        height: 24px;
        margin-right: 6px;
    }
}

.bodyRow {
    td {
        border-bottom: 0.5px dashed black;
        border-right: 0.5px solid black;
        &[data-missing="true"] {
            background: darken($color-grey6, 5);
        }
        &:last-child {
            border-right: none;
        }
    }
    &:last-child {
        border-bottom: 4px solid black;
    }
}

.domainLabelCell {
    @include font-bold;
    font-size: 16px;
    text-align: left;
    padding: 12px 8px;
    &[data-bottomstroke="true"] {
        border-bottom: 4px solid black;
    }
}

.scoreCell {
    @include font-bold;
    font-size: 16px;
    padding: 12px 8px;
    text-align: center;
}
