@import "./variables.scss";

body {
    margin: 16px;
    font-family: $font;
    font-size: pt(10);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
}

button {
    font-family: $font;
    background: white;
    outline: none;
    border: 2px solid $color-green;
    font-size: 24px;
    color: $color-green;
    padding: 8px 16px 11px 16px;
    cursor: pointer;
    border-radius: 8px;

    transition: background 0.2s;

    &:hover {
        background: rgba($color-green, 0.1);
    }
}
