@import "../../styles/variables.scss";

.verticalPerformance {
    width: 914px;
    height: 576px;
    display: flex;
    flex-direction: column;

    &[data-ranges="true"] {
        height: 656px;
    }
}

.container {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.svg {
    width: 100%;
    flex-grow: 1;
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.overallSignificance {
    @include font-light;
    font-size: 16px;
}

// ---------

.legendConfidenceInterval {
    width: 48px;
    height: 16px;
    background: rgba($color-green, 0.1);
    border-top: 1px solid $color-green;
    border-bottom: 1px solid $color-green;
    margin-right: 16px;
}

// -----

.confidenceExplainerLabel {
    @include font-light;
    font-size: 16px;
}

// ------

.chartDatum {
    &[data-datum="school"] {
        fill: $color-green;
        stroke: $color-green-dark;
        stroke-width: 1;
    }
    &[data-datum="oecd"] {
        fill: $color-oecd;
        stroke: $color-oecd-dark;
        stroke-width: 1;
    }
}

// ------

.rangeShape {
    fill: $color-grey3;
    stroke: $color-grey1;
    &[data-significant="false"] {
        fill: white;
    }
}

.rangeLine {
    stroke: $color-grey3;
    stroke-width: 1;
    shape-rendering: crispEdges;
}

// ------
.domainLabel {
    @include font-bold;
    text-align: center;
    position: absolute;
    transform: translate(-50%, -50%);
    white-space: pre-line;
}
