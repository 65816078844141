$color-grey1: rgb(35, 31, 32);
// $color-grey2: #4a4a4a;
$color-grey3: #777;
// $color-grey4: #949598;
$color-grey5: #aaa;
$color-grey6: #f3f3f3;
// $color-grey7: #f9f9f9;

// Primary colours
$color-green: #00ae88;
$color-green-dark: #005441;

$color-oecd-light: #037bc1;
$color-oecd: #04629a;
$color-oecd-dark: #004b78;
$color-oecd-darkest: #001b25;

$color-bar-red: #d04432;
$color-bar-red-dark: #611e16;
$color-bar-blue-light: #cde7f1;
$color-bar-blue: #0076a0;
$color-bar-blue-dark: #003a4f;

$color-bar-0: #cadeb8;
$color-bar-1: #a0cc63;
$color-bar-2: #4eac5b;
$color-bar-3: #729640;
$color-bar-4: #31793b;

$font: "futura-pt";
$font-bold: "futura-pt-bold";

$weight-light: 300;
$weight-reg: 500;
$weight-bold: 700;
$weight-heavy: 700;

// Wrapper in case we swap to px
@function pt($sizept) {
    @return ($sizept * 2) + px;
}

@mixin font-light {
    font-family: $font;
    font-weight: $weight-light;
}

@mixin font-reg {
    font-family: $font;
    font-weight: $weight-reg;
}

@mixin font-bold {
    font-family: $font-bold;
    font-weight: $weight-bold;
}

@mixin font-heavy {
    font-family: $font;
    font-weight: $weight-bold;
}
