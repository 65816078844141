@import "../../styles/variables.scss";

.table {
    width: 914px;
    border-spacing: 0;
}

.headerCell {
    @include font-bold;
    font-size: 20px;
    text-align: left;
    display: flex;
    flex-direction: row;
    padding: 0 16px 24px 16px;
    position: relative;
    width: 0; // yucky hack.
    img,
    svg {
        height: 24px;
        margin-right: 16px;
        position: absolute;
        left: -24px;
    }
}

.headerText {
    white-space: nowrap;
}

.bodyRow {
    td {
        border-bottom: 0.5px solid black;
    }

    &:first-child {
        td {
            border-top: 2px solid black;
        }
    }
    &:last-child {
        td {
            border-bottom: 4px solid black;
        }
    }
}

.domainLabelCell {
    @include font-bold;
    font-size: 20px;
    text-align: left;
    width: 240px;
    padding-right: 32px;
}

.scoreCell {
    @include font-bold;
    font-size: 28px;
    padding: 16px 8px;
    text-align: left;
    width: 68px;
}

.comparisonTextCell {
    @include font-light;
    text-align: center;
    padding: 16px 8px;
    font-size: 20px;
    min-width: 222px;
}
