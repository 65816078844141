@import "./variables.scss";

.gridLine {
    stroke: $color-grey5;
    stroke-width: 1px;
    stroke-dasharray: 2px 2px;

    &[data-bottom="true"] {
        stroke-dasharray: none;
        stroke: black;
    }
}

.gridLabel {
    @include font-light;
    text-anchor: end;
    font-size: 16px;

    &[data-align="end"] {
        text-anchor: end;
    }
    &[data-align="start"] {
        text-anchor: start;
    }
    &[data-align="middle"] {
        text-anchor: middle;
    }
}

.axisLine {
    stroke: black;
}

.axisText {
    @include font-light;
    font-size: 16px;
    &[data-align="end"] {
        text-anchor: end;
        text-align: right;
    }
    &[data-align="start"] {
        text-anchor: start;
        text-align: left;
    }
    &[data-align="middle"] {
        text-anchor: middle;
        text-align: center;
    }
}

// ------

.confidenceIntervalRect {
    fill: rgba($color-green, 0.1);

    &[data-grey="true"] {
        fill: rgba($color-grey3, 0.1);
    }
}

.confidenceIntervalLine {
    stroke: $color-green;
    stroke-width: 1;
    shape-rendering: crispEdges;

    &[data-grey="true"] {
        stroke: rgba($color-grey3, 0.5);
    }
}

.confidenceIntervalLabel {
    @include font-reg;
    font-size: 16px;
    fill: $color-green;
}

// -----

.flagStroke {
    fill: none;
    stroke: $color-grey3;
    stroke-width: 1px;
}

// ------

.labelOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.overlayLabel {
    @include font-light;
    position: absolute;
    font-size: 16px;
    line-height: 1.125;
    margin: 0;

    &[data-verticalalign="bottom"] {
        transform: translateY(-100%);
    }

    &[data-align="right"] {
        text-align: right;
        transform: translateX(-100%);
    }

    &[data-verticalalign="bottom"] {
        &[data-align="right"] {
            transform: translate(-100%, -100%);
        }
    }
}

// ---------

.legend {
    margin-top: 45px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;

    &[data-closer="true"] {
        margin-top: 14px;
    }

    &[data-align="middle"] {
        justify-content: center;
    }
}

.legendItem {
    display: flex;
    align-items: center;
    justify-content: center;

    &[data-align="top"] {
        align-items: flex-start;
    }

    &:not(:last-child) {
        margin-right: 40px;
    }
}

.legendIcon {
    margin-right: 16px;
    width: 16px;
    height: 16px;

    &[data-school="true"] {
        width: 20px;
        height: 20px;
    }
}

.legendIconGroup {
    display: flex;
    align-items: center;
}

.legendLabel {
    @include font-light;
    font-size: 16px;
    white-space: pre-wrap;

    &[data-nowrap="true"] {
        white-space: nowrap;
    }
}

.legendOecdIcon {
    @extend .legendIcon;
    background: $color-oecd;
    border: 1px solid $color-oecd-dark;
    border-radius: 50%;
}

.legendRangeIcon {
    path,
    rect {
        fill: $color-grey3;
        stroke: $color-grey1;
    }

    &[data-significant="false"] {
        path,
        rect {
            fill: white;
        }
    }

    margin-right: 8px;
    &:last-child {
        margin-right: 16px;
    }
}
