@import "../../styles/variables.scss";

$normal-grid-width: pt(0.3 * 2);
$thick-grid-width: pt(1.5 * 2);

.executiveSummary {
    background: white;
    color: black;
    width: pt(458);
    // height: pt(608.614);
    line-height: 1.2;
}

.table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    thead tr td {
        border-top: $thick-grid-width solid $color-grey1;
        border-bottom: $normal-grid-width solid $color-grey1;
        width: 25%;
        max-width: 25%;
        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    td {
        border: $normal-grid-width solid $color-grey1;
        border-bottom-style: dashed;
        border-top-style: dashed;
        padding: pt(10);
        width: 25%;
        max-width: 25%;

        &:last-child {
            border-right: none;
        }
        &:first-child {
            border-left: none;
        }
    }
}

.cellCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
}

.rowHeadingCell {
    vertical-align: top;
}

.columnHeading,
.rowHeading {
    @include font-bold;
    font-size: pt(12);
}
.columnHeading {
    text-align: center;
}

.rowPage {
    font-size: pt(10);
}

.rowScore {
    font-size: pt(16);
    @include font-bold;
}

.rowComparison {
    font-size: pt(12);
    @include font-heavy;
}

.topBorderThick {
    border-top: $thick-grid-width solid $color-grey1;
}

.bottomBorderThick {
    border-bottom: $thick-grid-width solid $color-grey1;
}

.socialRelationships {
    border-collapse: collapse;

    td {
        border: none;
        padding: 0;
        width: auto;
        max-width: auto;
    }
}

.socialRowDescription {
    padding: pt(4) !important;
    text-align: center;
}

.socialIcon {
    margin-right: pt(4);
}

.socialArrow {
    margin: 0 pt(5);
}

.socioeconomicDifferenceVis {
    display: flex;
    align-items: center;
    margin-bottom: pt(8);
}

.socioeconomicDifferenceIcon {
    &:first-child {
        margin-right: 4px;
    }
    &:last-child {
        margin-left: 4px;
    }
}

.socioeconomicDifferenceLine {
    height: 1.5px;
    border-radius: 1.5px;
    background: black;
}

.engagementItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.1;

    .rowScore {
        margin-right: pt(6);
        min-width: pt(38);
        text-align: right;
    }

    &:not(:last-child) {
        margin-bottom: pt(4);
    }
}

.engagementIcon {
    width: 27px;
    margin-right: pt(4);
    display: flex;
    justify-content: flex-end;
}

.genderIcon {
}

.highlight {
    color: $color-green;
}

.flagsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: pt(4);
}

.flags {
    display: flex;
    width: 100%;
}
.flagContainer {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:first-child {
        justify-content: flex-end;
    }
}
.flagImg {
    align-self: flex-end;
    height: pt(9.5);
    width: pt(9.5);
    margin-right: pt(3);
}
.oecdLogo {
    height: pt(9.5);
    width: pt(45);
    margin-right: pt(3);
}

.flagScore {
    font-size: pt(10);
    @include font-bold;
}

.resilienceDescription {
    margin-bottom: 30px;
}
