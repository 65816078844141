@import "../../styles/variables.scss";

.container {
    position: relative;
    width: 914px;
}

// -----

.svg {
    width: 914px;
    height: 550px;
}

// ------

.chartBar {
    fill: red;
    stroke: black;
    stroke-width: 1;

    &[data-grouped="true"] {
        &[data-level="-1"] {
            fill: $color-bar-red;
            stroke: $color-bar-red-dark;
        }
        &[data-level="0"] {
            fill: $color-bar-blue-light;
            stroke: $color-bar-blue;
        }
        &[data-level="1"] {
            fill: $color-bar-blue;
            stroke: $color-bar-blue-dark;
        }
    }
    &[data-grouped="false"] {
        &[data-level="-1"] {
            fill: $color-bar-red;
            stroke: $color-bar-red-dark;
        }
        &[data-level="0"] {
            fill: $color-bar-0;
            stroke: $color-bar-3;
        }
        &[data-level="1"] {
            fill: $color-bar-1;
            stroke: $color-bar-3;
        }
        &[data-level="2"] {
            fill: $color-bar-2;
            stroke: $color-bar-4;
        }
        &[data-level="3"] {
            fill: $color-bar-blue-light;
            stroke: $color-bar-blue;
        }
        &[data-level="4"] {
            fill: $color-bar-blue;
            stroke: $color-bar-blue-dark;
        }
    }
}

.quartileLine {
    stroke: $color-grey3;
}

// -----

.labelContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.domainLabel {
    @include font-bold;
    font-size: 18px;
    position: absolute;
    left: 0;
    white-space: pre-line;
    // transform: translateY(-50%);
}

.datumLabel {
    @include font-light;
    font-size: 16px;
    line-height: 1.125;
    position: absolute;
    left: 0;
    white-space: pre-line;
    // transform: translateY(-50%);
}

// -----

.legendQuartileIcon {
    composes: legendIcon from global;
    width: 12px;
    height: 12px;
    display: inline-block;

    background: white;
    border: 1px solid $color-grey1;

    margin-right: 16px;

    &[data-grouped="true"] {
        &[data-level="-1"] {
            background: $color-bar-red;
            border-color: $color-bar-red-dark;
        }
        &[data-level="0"] {
            background: $color-bar-blue-light;
            border-color: $color-bar-blue;
        }
        &[data-level="1"] {
            background: $color-bar-blue;
            border-color: $color-bar-blue-dark;
        }
    }
    &[data-grouped="false"] {
        &[data-level="-1"] {
            background: $color-bar-red;
            border-color: $color-bar-red-dark;
        }
        &[data-level="0"] {
            background: $color-bar-0;
            border-color: $color-bar-3;
        }
        &[data-level="1"] {
            background: $color-bar-1;
            border-color: $color-bar-3;
        }
        &[data-level="2"] {
            background: $color-bar-2;
            border-color: $color-bar-4;
        }
        &[data-level="3"] {
            background: $color-bar-blue-light;
            border-color: $color-bar-blue;
        }
        &[data-level="4"] {
            background: $color-bar-blue;
            border-color: $color-bar-blue-dark;
        }
    }
}
