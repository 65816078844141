@import "../../styles/variables.scss";

.container {
    position: relative;
}

// -----

.svg {
    width: 914px;
    height: 820px;
}

// ----

.pisaPoint {
    fill: none;
    stroke: $color-grey3;
    stroke-width: 1;
}

.schoolPoint {
    fill: $color-green;
    stroke: $color-green-dark;
}

.trendLine {
    stroke: $color-grey1;
    stroke-width: 2;
}

.escsIntervalLabel {
    @include font-light;
    font-size: 16px;
    text-anchor: middle;
}

// ----
.overlayLabel {
    composes: overlayLabel from global;
    width: 320px;
    max-width: 320px;
    // &[data-horizontalalign="right"] {
    //    right: 20px;
    // }    
}

.axisLabel {
    composes: axisText from global;
    position: absolute;
    transform: translate(-50%, -50%);
    margin: 0;
    max-width: 340px;
    white-space: pre-line;
}

.badgeArea {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 140px;
}

.badgeDomain {
    @include font-bold;
    font-size: 20px;
    text-align: center;
}

// ------
.legendSchoolIcon {
    composes: legendIcon from global;
    border: 1px solid black;
    background-color: white;
    border-radius: 50%;
}

.legendRegressionLine {
    composes: legendIcon from global;
    border: 1px solid black;
    height: 0px;
}
