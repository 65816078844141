@import "../../styles/variables.scss";

.container {
    position: relative;
}

// -----

.svg {
    width: 914px;
    height: auto;
}

.notes {
    margin-top: 42px;
    font-weight: $weight-light;
    font-size: 18px;
}

.notesHighlight {
    font-weight: $weight-reg;
    margin-right: 21px;
}

.relationshipParagraph {
    margin-top: 56px;
    font-size: 20px;
    line-height: 1.6;
    max-width: 914px;
}

// ------

.chartDatum {
    fill: white;
    stroke: black;
    stroke-width: 1;

    &[data-datum="quartile"],
    &[data-datum="country"] {
        fill: white;
        stroke: $color-grey1;

        &[data-significant="true"] {
            fill: $color-grey3;
        }
    }

    &[data-datum="school"] {
        fill: $color-green;
        stroke: $color-green-dark;

        &[data-significant="false"] {
            fill: white;
        }
    }
}

.quartileLine {
    stroke: $color-grey3;
}

// -----

.labelContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.domainLabel {
    @include font-light;
    font-size: 16px;
    line-height: 1.125;
    position: absolute;
    left: 0;
    transform: translateY(-50%);
}

// -----

.legendSchoolIcon {
    composes: legendIcon from global;

    &[data-significant="false"] {
        path {
            fill: white;
        }
    }

    &:not(:last-child) {
        margin-right: 4px;
    }
}

.legendQuartileIcon {
    composes: legendIcon from global;
    width: 11px;
    height: 11px;
    display: inline-block;

    background: white;
    border: 1px solid $color-grey1;

    &[data-significant="true"] {
        background: $color-grey3;
    }

    &[data-median="true"] {
        border-radius: 50%;
        width: 16px;
        height: 16px;
    }

    &[data-rotate="true"] {
        transform-origin: center center;
        transform: rotate(45deg);
    }

    &:not(:last-child) {
        margin-right: 4px;

        &[data-rotate="true"] {
            margin-right: 8px;
        }
    }
}

.legendSquare {
    composes: legendIcon from global;
    width: 11px;
    height: 11px;
    display: inline-block;

    background: white;
    border: 1px solid $color-grey1;

    &[data-significant="true"] {
        background: $color-grey3;
    }

    &[data-rotate="true"] {
        transform-origin: center center;
        transform: rotate(45deg);
    }

    &:not(:last-child) {
        margin-right: 4px;

        &[data-rotate="true"] {
            margin-right: 8px;
        }
    }
}
