@import "../../styles/variables.scss";

.container {
    position: relative;
}

// -----

.svg {
    width: 914px;
    height: auto;
}

// ------

.chartDatum {
    fill: white;
    stroke: black;
    stroke-width: 1;

    &[data-datum="country"] {
        fill: white;
        stroke: $color-grey1;

        &[data-significant="true"] {
            fill: $color-grey3;
        }
    }

    &[data-datum="oecd"] {
        fill: white;
        stroke: $color-oecd-light;

        &[data-significant="true"] {
            stroke: $color-oecd-darkest;
            fill: $color-oecd-dark;
        }
    }

    &[data-datum="school"] {
        fill: $color-green;
        stroke: $color-green-dark;
    }
}

.schoolFillRect {
    fill: rgba($color-green, 0.3);
}

// -----

.labelContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.domainLabel {
    @include font-light;
    font-size: 16px;
    line-height: 1.125;
    position: absolute;
    left: 0;
    transform: translateY(-50%);

    &[data-group="true"] {
        @include font-reg;
    }
}

// -----

.legendCircle {
    composes: legendIcon from global;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: red;
    border: 1px solid transparent;
    display: inline-block;
    box-sizing: border-box;

    &[data-datum="country"] {
        background: white;
        border-color: $color-grey1;

        &[data-significant="true"] {
            background: $color-grey3;
        }
    }

    &[data-datum="oecd"] {
        background: white;
        border-color: $color-oecd-light;

        &[data-significant="true"] {
            border-color: $color-oecd-darkest;
            background: $color-oecd-dark;
        }
    }

    &:not(:last-child) {
        margin-right: 4px;
    }
}

.legendSquare {
    composes: legendIcon from global;
    width: 11px;
    height: 11px;
    display: inline-block;

    background: white;
    border: 1px solid $color-grey1;

    &[data-significant="true"] {
        background: $color-grey3;
    }

    &[data-rotate="true"] {
        transform-origin: center center;
        transform: rotate(45deg);
    }

    &:not(:last-child) {
        margin-right: 4px;

        &[data-rotate="true"] {
            margin-right: 8px;
        }
    }
}
